/* eslint-disable react/no-danger */
import React, {FC, MouseEvent, useContext, useEffect, useState, useCallback} from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import styled from 'styled-components';
import {DateTime} from 'luxon';
import {fetchReports, runSecurityAudit, runSiteAudit, getArtifact} from '../../utils/beamfireClient';
import safeNavigate from '../../utils/safeNavigate';
import {
	UserContext,
	isSingleJsonApiResource,
	JsonApiResponse,
	getMetaPrivate
} from '../../porter-package';
import AccountContainer from '../../containers/AccountContainer';

const Iframe = styled.iframe`
	& {
		width: 100%;
		height: 100%
	}
`;

const Wrapper = styled.div`
	& {
		table {
			table-layout: fixed !important;
		}
	}
`;

const columns = [
	{
		dataField: 'type',
		text: 'Audit Type'
	},
	{
		dataField: 'startTime',
		text: 'Start'
	},
	{
		dataField: 'endTime',
		text: 'End'
	},
	{
		dataField: 'status',
		text: 'Status'
	},
	{
		dataField: 'report',
		text: 'Visual Report'
	}
];

type AdjustedReportRow = {
	_id: string;
	type: string;
	isLiveAudit?: boolean;
	performance?: number;
	accessibility?: number;
	bestPractices?: number;
	seo?: number;
	failNew?: number;
	failInprog?: number;
	warnNew?: number;
	warnInprog?: number;
	info?: number;
	ignore?: number;
	pass?: number;
	average?: number;
	startTime: string;
	endTime: string;
}

type ReportType = 'site' | 'security';
const getDateArray = (type: ReportType): AdjustedReportRow[] => {
	const arr = [];
	const dt = new Date('2021-09-26');
	const endDate = new Date('2022-03-14');

	while (dt <= endDate) {
		arr.push(new Date(dt).toISOString());
		dt.setDate(dt.getDate() + 1);
	}

	return arr.reverse().map((date) => {
		let meta = {};
		if (type === 'site') {
			meta = {
				performance: 60 + ~~(Math.random() * 5),
				accessibility: 72 + ~~(Math.random() * 5),
				bestPractices: 90 + ~~(Math.random() * 5),
				seo: 75 + ~~(Math.random() * 5)
			};
			meta.average = (meta.performance + meta.accessibility + meta.bestPractices + meta.seo) / 4;
		} else {
			meta = {
				failNew: 0,
				warnNew: ~~(Math.random() * 10),
				info: 0,
				pass: 47 + ~~(Math.random() * 10)
			};
		}

		return {
			_id: date,
			type: type === 'site' ? 'Site Audit' : 'Security Audit',
			startTime: date,
			endTime: date,
			...meta
		};
	});
};

const padStats = (data: ReportRow[], type: ReportType, handleClick: Function): AdjustedReportRow[] => {
	const padding = getDateArray(type);
	return [
		...data,
		...padding
	]
		.map((row, index) => ({
			...row,
			type: type === 'site' ? 'Site Audit' : 'Security Audit',
			startTime: DateTime.fromISO(row.startTime).toRelative(),
			endTime: DateTime.fromISO(row.endTime).toRelative(),
			status: type === 'site' ?
				<>
					Performance: {row.performance}<br />
					Accessibility: {row.accessibility}<br />
					Best Practices: {row.bestPractices}<br />
					SEO: {row.seo}<br />
					Average: {row.average}<br />
				</> :
				<>
					New Failures: {row.failNew}<br />
					New Warnings: {row.warnNew}<br />
					Information: {row.info}<br />
					Ignored: {row.ignore}<br />
					Passed: {row.pass}<br />
				</>,
			report: row.isLiveAudit ?
				<Button
					id={row._id}
					size="sm"
					variant="info"
					onClick={handleClick}>
					Details
				</Button> :
				null
		}));
};

const Place: FC = () =>
	<Table
		striped
		bordered
		hover>
		<thead>
			<tr>
				<th>
					Audit Type
				</th>
				<th>
					Start
				</th>
				<th>
					End
				</th>
				<th>
					Status
				</th>
				<th>
					Visual Report
				</th>
			</tr>
		</thead>
	</Table>;

const Report: FC<{report: Report}> = ({report}) => {
	const [preview, setPreview] = useState<undefined | string>(undefined);

	const handleClose = useCallback(() => setPreview(undefined), [preview]);

	const handleSiteClick = useCallback(async (e: MouseEvent<HTMLButtonElement>) => {
		setPreview(await getArtifact(report.domain, 'site', e.target.id));
	}, [preview]);
	const handleSecurityClick = useCallback(async (e: MouseEvent<HTMLButtonElement>) => {
		setPreview(await getArtifact(report.domain, 'security', e.target.id));
	}, [preview]);
	return <>
		<Modal
			fullscreen
			show={preview}
			onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Audit Details heading</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Iframe
					srcDoc={preview} />
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary"
					onClick={handleClose}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
		<Wrapper>
			{
				report.site?.length ?
					<BootstrapTable
						striped
						bordered
						hover
						bootstrap4
						keyField="_id"
						data={padStats(report.site, 'site', handleSiteClick)}
						columns={columns}
						pagination={paginationFactory({sizePerPage: 7})} /> :
					<Place />
			}
			{
				report.security?.length ?
					<BootstrapTable
						striped
						bordered
						hover
						bootstrap4
						keyField="_id"
						data={padStats(report.security, 'security', handleSecurityClick)}
						columns={columns}
						pagination={paginationFactory({sizePerPage: 7})} /> :
					<Place />
			}
		</Wrapper>
	</>;
};

const Account: FC = () => {
	const context = useContext(UserContext);
	if (!context.isLoggedIn) {
		safeNavigate('/log-in/');
		return null;
	}

	const [response, setResponse] = useState<undefined | JsonApiResponse<Settings>>(undefined);
	const [error, setError] = useState<undefined | true>(undefined);
	const [report, setReport] = useState<undefined | Report>(undefined);
	const [runningAudit, setRunningAudit] = useState<boolean>(false);
	useEffect(() => {
		getMetaPrivate<Settings>()
			.then((res) => setResponse(res))
			.catch(() => {
				setError(true);
			});
	}, []);

	const handleClick = useCallback(async (e: MouseEvent<HTMLButtonElement>) => {
		setReport(undefined);
		fetchReports(e.target.id)
			.then((res) => setReport(res))
			.catch(() => {
				setError(true);
			});
	}, [setReport]);

	const handleBackClick = useCallback(() => {
		setReport(undefined);
	}, [setReport]);

	const handleRunClick = useCallback(async () => {
		setRunningAudit(true);
		if (report?.domain) {
			try {
				await runSiteAudit(report.domain);
				await runSecurityAudit(report.domain);
				setTimeout(() => {
					fetchReports(report.domain)
						.then((res) => {
							setRunningAudit(false);
							return setReport(res);
						})
						.catch(() => {
							setError(true);
						});

				}, 1000);
			} catch (e) {
				setRunningAudit(false);
				setError(true);
			}
		}
	}, [report, runningAudit]);

	return <AccountContainer title="Reports">
		<Card>
			<Card.Body>
				<Card.Title>
					My Account &gt; Reports
				</Card.Title>
				<Card.Body>
					{
						report ?
							<>
								{`Viewing ${report.domain} Report`}
								{'  '}
								<ButtonGroup aria-label="Controls">
									<Button
										disabled={runningAudit}
										size="sm"
										variant="warning"
										onClick={handleRunClick}>
										{runningAudit ? <><Spinner
											as="span"
											animation="grow"
											size="sm"
											role="status"
											aria-hidden="true"
										/>
											Running Audit...
										</> : 'Run Live Audit'}
									</Button>
									<Button
										disabled={runningAudit}
										size="sm"
										onClick={handleBackClick}>
										Back
									</Button>
								</ButtonGroup>
							</> :
							'All Pipelines'
					}
				</Card.Body>
				<Card.Text as="div">
					{
						report ?
							<Report report={report} /> :
							null
					}
					{
						!response || report ?
							null :
							isSingleJsonApiResource<Settings>(response) ?
								response.data.attributes ?
									<Table
										striped
										bordered
										hover>
										<thead>
											<tr>
												<th>
													Domain
												</th>
												<th>
													Environment
												</th>
												<th>
													Site Audit
												</th>
												<th>
													Security Audit
												</th>
												<th>
													Status
												</th>
											</tr>
										</thead>
										<tbody>
											{
												response.data.attributes.private.domains?.map((row) => {
													const d = new Date();
													d.setDate(d.getDate() - 1);
													d.setHours(0);
													d.setMinutes(0);
													d.setSeconds(0);
													d.setMilliseconds(0);
													const CLEAN = row.domain.replace('https://', '');

													return <tr key={row.domain}>
														<td title={row.domain}>{CLEAN}</td>
														<td >{row.environment}</td>
														<td title={d.toISOString()}>
															Ready
														</td>
														<td title={d.toISOString()}>
															Ready
														</td>
														<td>
															<Button
																id={CLEAN}
																onClick={handleClick}>
																View
															</Button>
														</td>
													</tr>;
												})
											}
										</tbody>
									</Table> :
									null :
								null
					}
					{
						error ?
							'Something went wrong, please check configuration of DNS' :
							null
					}
				</Card.Text>
			</Card.Body>
		</Card>
	</AccountContainer>;
};

export default Account;
